import styled from "styled-components";
import { Link } from "react-router-dom";

import globalStyles from "../../styles/global";
import Icon from "../fields/icon";

const { colors, mediaQueries } = globalStyles;

const Footer = styled.div`
  background: ${colors.black.regular};
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;

  @media screen and (${mediaQueries.mobile}) {
    height: 270px;
  }
`;

const Mark = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  letter-spacing: 0.03em;
  text-align: center;
  color: ${colors.grey.darker};
  border-top: 1px solid ${colors.grey.darker};

  @media screen and (${mediaQueries.tablet}) {
    font-size: 16px;
    padding: 20px 0px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 12px;
    padding: 0px;
  }
`;

const Container = styled.div`
  padding: 20px 80px;

  @media screen and (${mediaQueries.tablet}) {
    padding: 24px 40px;
  }

  @media screen and (${mediaQueries.mobile}) {
    padding: 16px;
  }
`;

const Sub = styled.p`
  font-family: var(--primaryHeaderFont);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  letter-spacing: 0.03em;
  margin-top: 14px;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 14px;
    line-height: 18px;
    margin-top: 16px;
  }
`;

const Social = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 16px;
  > div {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background: #ffffff;

    svg {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (${mediaQueries.mobile}) {
    margin-top: 8px;
    gap: 12px;

    > div {
      width: 16px;
      height: 16px;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;

  > a {
    text-decoration: none;
    font-family: var(--primaryRegularFont);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: ${colors.grey.darker};
  }

  @media screen and (${mediaQueries.tablet}) {
    margin-top: 12px;
    > a {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    margin-top: 8px;
    > a {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const Name = styled.div`
  font-family: var(--primaryHeaderFont);
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 9px;
  color: ${colors.white};

  @media screen and (${mediaQueries.tablet}) {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 7px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 5px;
  }
`;

export default function footer() {
  return (
    <Footer>
      <Container>
        <Name>MIKLOSET</Name>
        <Sub>Customer Care</Sub>
        <Grid>
          <Link to={"/dashboard/aboutus"}>About Us</Link>
          <Link to={"/privacy_policy"}>Privacy Policy</Link>
          <Link to={"/dashboard/contactUs"}>Contact Us</Link>
          <a>For California only</a>
          <Link to={""}>Track your Order</Link>
          <a href="https://app.termly.io/notify/63dcbf4b-02e5-4961-83f4-388b9300b588">- Do Not Sell or Share My Personal information</a>
          <Link to={"/dashboard/faq"}>FAQ</Link>
          <a href="https://app.termly.io/notify/63dcbf4b-02e5-4961-83f4-388b9300b588">- Limit the Use Of My Sensitive Personal Information</a>
          <Link to={""}>Interest-Based Ads</Link>
          <Link to={""}>Terms & Conditions</Link>
        </Grid>
        <Sub>Connect</Sub>
        <Social>
          <div>
            <Icon iconTitle={"InstagramIcon"} />
          </div>
          <div>
            <Icon iconTitle={"FacebookIcon"} />
          </div>
          {/* <div>
            <Icon iconTitle={"TwitterIcon"} />
          </div> */}
          <div>
            <Icon iconTitle={"TwitterXIcon"} />
          </div>
        </Social>
      </Container>
      <Mark>FashionForwardInnovations, LLC</Mark>
    </Footer>
  );
}
